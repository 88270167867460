import React from 'react';

import { Layout, Seo, Hero } from 'components';

const HomePage: React.FC = props => (
  <Layout>
    <Seo />
    <Hero />
  </Layout>
)

export default HomePage;
